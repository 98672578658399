import nanoid from 'nanoid';

/**
 * Makes a unique string to the required length
 *
 * @param {number} length desired number of characters
 * @returns {string} unique string
 */
export const uuid = (length = 10) => nanoid(length); // eslint-disable-line no-magic-numbers

export default uuid;
