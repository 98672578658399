import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { camelToKebab } from '../../utils/strings';

const INVERTED_TEXT_COLORS = [
  'chicago-20',
  'chicago-30',
  'chicago-45',
  'chicago-55',
  'economist-red',
  'london-5',
  'london-10',
  'london-20',
  'london-35',
];

export const DEFAULT_COLOR = 'london-100';

export const getBorderColor = ({ colorVar, bgColor, theme }) => {
  if (bgColor) return bgColor;
  return colorVar === DEFAULT_COLOR
    ? theme.getColorVar('london-85')
    : theme.getColorVar(colorVar);
};

export const getBackgroundColor = ({ colorVar, bgColor, theme }) =>
  bgColor || theme.getColorVar(colorVar);

export const getTextColor = ({ colorVar, color, theme }) => {
  if (color) return color;
  return INVERTED_TEXT_COLORS.includes(colorVar.toLowerCase())
    ? theme.getColorVar('london-100')
    : theme.getColorVar('london-5');
};

export const ColorBlockTitle = styled(
  ({ colorVar, bgColor, color, label, ...props }) => (
    <div {...props}>
      <span>
        {label || bgColor || camelToKebab(colorVar).replace(/-/g, ' ')}
      </span>
    </div>
  ),
)`
  color: ${(p) => getTextColor(p)};
  font-family: var(--ds-type-system-sans-lining);
  font-size: var(--ds-type-scale-1);
  font-weight: 700;
  line-height: var(--ds-type-leading-lower);
  text-transform: capitalize;
  ${({ theme }) => theme.mediaMaxWidth.medium`
    font-size: var(--ds-type-scale-0);
  `}
`;

ColorBlockTitle.propTypes = {
  colorVar: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

ColorBlockTitle.defaultProps = {
  colorVar: DEFAULT_COLOR,
  bgColor: undefined,
  color: undefined,
  label: undefined,
};

export const ColorBlock = styled(
  ({ colorVar, bgColor, color, label, ...props }) => (
    <div {...props}>
      <ColorBlockTitle
        colorVar={colorVar}
        bgColor={bgColor}
        color={color}
        label={label}
      />
    </div>
  ),
)`
  background-color: ${(p) => getBackgroundColor(p)};
  border: 0.0625rem solid ${(p) => getBorderColor(p)};
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  width: 10rem;
  ${ColorBlockTitle} {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    > span {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      margin: auto;
      padding: 0.6em;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMaxWidth.medium`
    width: 8rem;
  `}
`;

ColorBlock.propTypes = {
  colorVar: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

ColorBlock.defaultProps = {
  colorVar: DEFAULT_COLOR,
  bgColor: undefined,
  color: undefined,
  label: undefined,
};

export const ColorBlockSmall = styled(ColorBlock)`
  width: 7rem;
  ${ColorBlockTitle} {
    font-size: var(--ds-type-scale-0);
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMaxWidth.medium`
    width: 7rem;
  `}
`;

export const ColorGrid = styled.div`
  display: grid;
  grid-gap: var(--ds-grid-gutter);
  grid-template-columns: repeat(auto-fit, 10rem);
  ${({ theme }) => theme.mediaMaxWidth.medium`
    grid-gap: var(--ds-grid-gap);
    grid-template-columns: repeat(auto-fit, 8rem);
  `}
`;

export const ColorPair = styled.div`
  border-bottom: var(--ds-border-rule);
  border-top: var(--ds-border-rule);
  display: grid;
  grid-column-gap: var(--ds-grid-gap);
  grid-template-columns: repeat(2, 7rem);
  /* hides bottom-borders so we don't have to worry about nth-child */
  margin-bottom: -1px;
  padding: var(--ds-grid-gap) 0;
`;

export const ColorPairs = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  /* 2x ColorPair = 7rem + 7rem + 1rem gap */
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
`;

export const ColorCollection = styled.div`
  border-bottom: var(--ds-border-rule);
  border-top: var(--ds-border-rule);
  display: grid;
  grid-column-gap: var(--ds-grid-gap);
  grid-template-columns: repeat(auto-fill, 7rem);
  padding: 1rem 0 0;
  ${ColorBlock} {
    margin-bottom: 1rem;
  }
`;
