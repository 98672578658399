import React from 'react';
import styled from 'styled-components';

import Link from '../link/link';
import { EMAIL_FEEDBACK } from '../../constants';

const StrongText = styled.strong``;

export const Feedback = styled((props) => (
  <aside {...props}>
    <StrongText>Something wrong or missing on this page? </StrongText>
    <Link to={`mailto:${EMAIL_FEEDBACK}`}>Leave some feedback</Link>
  </aside>
))`
  background-color: var(--ds-color-los-angeles-95);
  border: 0.25rem solid var(--ds-color-los-angeles-90);
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  line-height: var(--ds-type-leading-lower);
  padding: 0.6rem;
  text-align: center;
  ${StrongText} {
    font-weight: 500;
  }
  ${Link} {
    border-bottom: 0.0625rem solid var(--ds-color-chicago-45);
    color: var(--ds-color-london-5);
    text-decoration: none;
    transition: var(--ds-interactions-transition);
    &:hover {
      border-bottom-color: var(--ds-color-chicago-30);
      color: var(--ds-color-chicago-30);
    }
    &:focus {
      border-bottom-color: transparent;
      box-shadow: 0 0 0 0.0625rem var(--ds-color-hong-kong-55);
      outline: solid transparent;
    }
    &:active {
      border-bottom-color: var(--ds-color-hong-kong-55);
      box-shadow: none;
      color: var(--ds-color-london-5);
    }
  }
`;

export default Feedback;
