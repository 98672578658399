import styled from 'styled-components';

export const ComponentSidebarSection = styled.section``;

export const ComponentSidebarSectionTitle = styled.h4`
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale--3);
  font-weight: 500;
  letter-spacing: 0.03125rem;
  line-height: var(--ds-type-leading-lower);
  margin: 0 0 0.8em;
  text-transform: uppercase;
`;

export const ComponentSidebarSectionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ComponentSidebarSectionItem = styled.li`
  padding: 0 0 0.5rem 0;
  .ds-form-checkbox__box,
  .ds-form-checkbox input {
    height: 1rem;
    min-width: 1rem;
    width: 1rem;
  }
  .ds-form-checkbox__box::after {
    height: 0.2rem;
    left: 0.2rem;
    top: 0.25rem;
    width: 0.5rem;
  }
  .ds-form-check-label {
    font-size: var(--ds-type-scale--1);
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const ComponentSidebar = styled.aside`
  padding: 1rem;
`;
