import React from 'react';
import { uuid } from '../../utils/uuid';

/**
 * Accepts a component string and inverts any boolean prop
 *
 * @param {string} componentStr String of the component to parse
 * @param {object} hookedProp hooked state with the prop name
 * @param {string} hookedProp.propName prop name
 * @param {bool} hookedProp.state state to replace
 * @returns {string} new component string
 */
export const invertComponentProp = (
  componentStr,
  { propName, state, componentName },
) => {
  const matcher = new RegExp(`(${propName})(={.+})?`);
  const replacer = `${propName}={${state}}`;
  return matcher.test(componentStr)
    ? componentStr.replace(matcher, replacer)
    : // if the toggle property can't find a matching prop on the component
      // then inject prop with the current state, eg:
      // <Button /> => <Button disabled={false} />
      componentStr.replace(
        `<${componentName}`,
        `<${componentName} ${replacer}`,
      );
};

/**
 * Transforms a component/code string by replacing the properies of the
 * component with their current states.
 *
 * This is a curried function which first accepts a list of props/states and
 * is mean to be used as a callback in `transformCode` on <LiveProvider />
 *
 * @param {object[]} hookedProps object of properties with `useState` hooks
 * @param {string} hookedProps[].propName name of the prop
 * @param {string|bool} hookedProps[].state current state of the prop
 * @param {string} componentStr string version of the component/code to be transformed
 * @returns {string} the new component/code string
 */
export const makeComponentPropInvertReducer = (hookedProps) => (componentStr) =>
  hookedProps.reduce(invertComponentProp, componentStr);

/**
 * Returns an array of objects containing prop names, their current states and
 * teh hook fn required to toggle them.
 *
 * The argument in the first fn accepts a component string to compare against,
 * whereas the curried fn is a reducer used with an array of prop string names.
 *
 * @param {string} componentStr string of the component to compare against
 * @param {string} componentName displayName of the component
 * @param {object[]} hookedProps empty object array of properties with `useState` hooks
 * @param {string} propName prop name
 * @returns {object[]} hookedProps object of properties with `useState` hooks
 */
export const makeComponentPropsToHooksReducer = (
  componentStr,
  componentName,
) => (_hookedProps, propName) => {
  const regexp = new RegExp(`${propName}(={true})?(?=[\\s|>]+)`);
  const currentState = regexp.test(componentStr);
  const [state, setState] = React.useState(currentState);
  const toggleState = React.useCallback(() => setState(!state), [state]); // memoized
  const id = uuid();
  _hookedProps.push({
    id,
    state,
    propName,
    toggleState,
    componentName,
  });
  return _hookedProps;
};
