import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { FormCheckbox } from '@economist/design-system/dist/umd/common';
import { editorTheme } from './theme';
import { routes } from '../../constants';

import {
  ComponentSidebar,
  ComponentSidebarSection,
  ComponentSidebarSectionList,
  ComponentSidebarSectionItem,
  ComponentSidebarSectionTitle,
} from '../component-sidebar/component-sidebar';
import ComponentFrame from '../component-frame/component-frame';

import {
  makeComponentPropsToHooksReducer,
  makeComponentPropInvertReducer,
} from './utils';

const PlaygroundContainer = styled.div`
  border-radius: 0.125rem 0.125rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${ComponentSidebar} {
    border: var(--ds-border-rule);
    border-top: none;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    border: var(--ds-border-rule);
    flex-direction: row;
    ${ComponentSidebar} {
      border: none;
      border-left: var(--ds-border-rule);
    }
  `}
`;

const StyledEditor = styled(LiveEditor)`
  border: var(--ds-border-rule);
  border-radius: 0 0 0.125rem 0.125rem;
  border-top: 0;
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  line-height: var(--ds-leading-lower);
`;

const StyledError = styled(LiveError)`
  background: hsl(326, 79%, 96%);
  border-left: 0.25rem solid var(--ds-color-tokyo-45);
  border-radius: 0.125rem;
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  line-height: var(--ds-leading-lower);
  margin: 1rem 0 0;
  padding: 0.5rem var(--ds-grid-gap);
`;

const NewTabLink = styled.a`
  align-items: center;
  color: var(--ds-color-london-5);
  display: inline-flex;
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale--1);
  font-weight: 400;
  line-height: var(--ds-type-leading-border-link);
  position: absolute;
  right: 0;
  text-decoration: underline;
  top: -2rem;
  &:hover {
    color: var(--ds-color-chicago-30);
  }
  &:focus {
    box-shadow: 0 0 0 0.0625rem var(--ds-color-hong-kong-55);
    outline: solid transparent;
  }
  &:active {
    box-shadow: none;
    color: var(--ds-color-chicago-30);
  }
`;

const Playground = ({
  component,
  componentName,
  scope,
  className,
  toggleProps,
  background,
  newTabLink,
  style,
  iframe,
  pagePath,
}) => {
  const componetString = component.trim();
  const componentPropsToHooksReducer = makeComponentPropsToHooksReducer(
    componetString,
    componentName,
  );
  const hookedProps = toggleProps.reduce(componentPropsToHooksReducer, []);
  const codeTransformer = makeComponentPropInvertReducer(hookedProps);

  const newTabUrl = () => {
    const url = `${routes.PLAYGROUND}${pagePath}/${newTabLink}`;

    return encodeURI(url);
  };

  return (
    <div className={className}>
      {newTabLink && (
        <NewTabLink href={newTabUrl()} target='_blank'>
          Open in a new tab
        </NewTabLink>
      )}
      <LiveProvider
        code={componetString}
        scope={scope}
        language='jsx'
        transformCode={codeTransformer}
        theme={editorTheme}
      >
        <PlaygroundContainer>
          <ComponentFrame background={background} iframe={iframe}>
            <LivePreview style={style} />
          </ComponentFrame>
          {!!hookedProps.length && (
            <ComponentSidebar>
              <ComponentSidebarSection>
                <ComponentSidebarSectionTitle>
                  States
                </ComponentSidebarSectionTitle>
                <ComponentSidebarSectionList>
                  {hookedProps.map(({ id, propName, state, toggleState }) => (
                    <ComponentSidebarSectionItem key={`toggle-${propName}`}>
                      <FormCheckbox
                        id={id}
                        defaultChecked={state}
                        labelText={propName}
                        onChange={toggleState}
                      />
                    </ComponentSidebarSectionItem>
                  ))}
                </ComponentSidebarSectionList>
              </ComponentSidebarSection>
            </ComponentSidebar>
          )}
        </PlaygroundContainer>
        <StyledEditor />
        <StyledError />
      </LiveProvider>
    </div>
  );
};

Playground.defaultProps = {
  background: 'default',
  className: '',
  toggleProps: [],
  scope: {},
  componentName: null,
  style: {},
  iframe: false,
  newTabLink: null,
  pagePath: '',
};

Playground.propTypes = {
  component: PropTypes.string.isRequired,
  componentName: PropTypes.string,
  scope: PropTypes.shape({}),
  background: PropTypes.oneOf(['default', 'inverse', 'branded']),
  className: PropTypes.string,
  toggleProps: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.shape({}),
  iframe: PropTypes.bool,
  newTabLink: PropTypes.string,
  pagePath: PropTypes.string,
};

export default styled(Playground)`
  /* styles here */
`;
