/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as DsComponents from '@economist/design-system/dist/umd/common';

import * as propTypes from '../proptypes';
// import { HIDE_FEEDBACK_URLS } from '../constants';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import ComponentProps from '../components/component-props/component-props';
import Playground from '../components/playground/playground';
import { TableOfContents } from '../components/table-of-contents/table-of-contents';
import {
  ARTICLE_START_ID,
  ARTICLE_END_ID,
} from '../components/table-of-contents/table-of-contents-hooks';
import * as Mdx from '../components/mdx-components/mdx-components';
import * as Colors from '../components/colour-block/colour-block';
import Feedback from '../components/feedback/feedback';
import Link from '../components/link/link';
import { getHeadingsFromMdxAst } from '../components/table-of-contents/utils';

const ArticleStart = styled.div.attrs({ id: ARTICLE_START_ID })``;
const ArticleEnd = styled.div.attrs({ id: ARTICLE_END_ID })``;

const Article = styled(Mdx.MdxArticle)`
  display: grid;
  padding: 1.5rem 0 var(--ds-grid-gutter);
  position: relative;
  /* brute grid layout of all children; clean up following header and body containers */
  > * {
    grid-area: auto / 1 / auto / -1;
  }
  svg {
    height: auto;
    max-width: 100%;
  }
  ${TableOfContents} {
    grid-area: 4 / 1 / span 1 / -1;
    width: 100%;
  }
  /* stylelint-disable-next-line order/order */
  ${Mdx.MdxCategory} + ${TableOfContents} {
    grid-area: 5 / 1 / span 1 / -1;
  }
  ${Playground} {
    margin: 0 0 2.5rem 0;
    position: relative;
    + ${Playground} {
      margin-top: 2.5rem;
    }
    + ${Mdx.MdxHr} {
      margin-top: 1.5rem;
    }
  }
  ${Feedback} {
    margin: 10rem 0 4rem;
  }
  ${ComponentProps} {
    margin: 1rem 0 0;
  }
  .ds-table--scroll {
    margin: 1rem 0 2rem;
    + ${Mdx.MdxHr} {
      margin-top: 2rem;
    }
  }
  ${Colors.ColorCollection} {
    margin: 0.75rem 0 1rem;
    + ${Mdx.MdxHr} {
      margin-top: 3rem;
    }
  }
  ${Colors.ColorPairs} {
    margin: 1rem 0;
    + ${Mdx.MdxHr} {
      margin-top: 3rem;
    }
  }
  ${Colors.ColorGrid} {
    margin: 1.5rem 0;
    + .ds-table--scroll {
      margin-top: 0.25rem;
    }
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.large`
    grid-auto-flow: row;
    grid-column-gap: 1.5rem;
    grid-template: auto / repeat(9, 1fr);
    > * {
      grid-area: auto / 1 / auto / -1;
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    > * {
      grid-area: auto / 1 / auto / -3;
    }
    ${Mdx.MdxCategory} + ${TableOfContents},
    ${TableOfContents} {
      grid-area: 1 / 8 / auto / -1;
    }
    ${TableOfContents} {
      /* ensures TOC doesn't push any sibling elements onto next grid row */
      height: 0;
      position: sticky;
      top: 1.5rem;
    }
  `}
`;

export const makeProviderComponents = ({
  componentMetadata,
  frontmatter,
  headings,
  path,
}) => {
  const providerComponents = {
    Seo,
    Link,
    Playground,
    MdxHeader: Mdx.MdxHeader,
    MdxHeaderSecondary: Mdx.MdxHeaderSecondary,
    MdxHeaderTertiary: Mdx.MdxHeaderTertiary,
    MdxHeaderTertiaryVariant: Mdx.MdxHeaderTertiaryVariant,
    MdxLede: Mdx.MdxLede,
    MdxHr: Mdx.MdxHr,
    MdxP: Mdx.MdxP,
    MdxFigure: Mdx.MdxFigure,
    MdxFigurePair: Mdx.MdxFigurePair,
    // Force the standard markdown elements to use a specific component
    h1: Mdx.MdxH1,
    h2: Mdx.MdxH2,
    h3: Mdx.MdxH3,
    p: Mdx.MdxP,
    hr: Mdx.MdxHr,
    // bespoke components
    ColorGrid: Colors.ColorGrid,
    ColorPair: Colors.ColorPair,
    ColorPairs: Colors.ColorPairs,
    ColorCollection: Colors.ColorCollection,
    ColorBlock: Colors.ColorBlock,
    ColorBlockSmall: Colors.ColorBlockSmall,
  };

  if (frontmatter.navigationLabel) {
    providerComponents.Seo = (props) => (
      <Seo title={frontmatter.navigationLabel} {...props} />
    );
  }

  if (componentMetadata) {
    const { displayName } = componentMetadata;
    // automatically adds the component scope to the Playground
    // so there's less to write in the markup
    providerComponents.Playground = (props) => (
      <Playground
        scope={{ [displayName]: DsComponents[displayName] }}
        componentName={displayName}
        pagePath={path}
        {...props}
      />
    );
    // Allows us to just use <ComponentProps /> in the markup
    providerComponents.ComponentProps = (props) => (
      <ComponentProps componentMeta={componentMetadata} {...props} />
    );
  }

  // Ensure that all tertiary headings have a unique id in order to work correctly
  // with the table of contents.
  if (headings) {
    const tertiaryHeadings = headings.filter((heading) => heading.depth === 3);
    const uniqueId = (() => {
      let i = 0;
      return () => {
        if (tertiaryHeadings[i] && tertiaryHeadings[i].id) {
          const { id } = tertiaryHeadings[i];
          i += 1;
          return { id };
        }
        return {};
      };
    })();

    providerComponents.MdxHeaderTertiaryVariant = (props) => (
      <Mdx.MdxHeaderTertiaryVariant {...uniqueId()} {...props} />
    );
    providerComponents.MdxHeaderTertiary = (props) => (
      <Mdx.MdxHeaderTertiary {...uniqueId()} {...props} />
    );
    providerComponents.h3 = (props) => <Mdx.MdxH3 {...uniqueId()} {...props} />;
  }

  return providerComponents;
};

export const PageTemplate = ({ data, ...pageProps }) => {
  const { mdx, componentMetadata } = data;
  const headingsData = getHeadingsFromMdxAst(mdx.mdxAST);
  const headings = headingsData.length > 0 ? headingsData : null;
  const MdxProviderComponents = makeProviderComponents({
    componentMetadata,
    frontmatter: mdx.frontmatter,
    headings,
    path: pageProps.path,
  });
  return (
    <Layout {...pageProps}>
      {({ currentCategory }) => (
        <Article>
          <ArticleStart />
          {currentCategory && currentCategory.hasChildren && (
            <Mdx.MdxCategory>{currentCategory.navigationLabel}</Mdx.MdxCategory>
          )}
          {headings && (
            <TableOfContents headings={headings} currentUri={pageProps.uri} />
          )}
          <MDXProvider components={MdxProviderComponents}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
          {
            //! HIDE_FEEDBACK_URLS.includes(pageProps.uri) && <Feedback />
          }
          <ArticleEnd />
        </Article>
      )}
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: propTypes.mdx.isRequired,
    componentMetadata: propTypes.componentMetadata,
  }).isRequired,
};

export const pageQuery = graphql`
  query MdxQuery($id: String!, $componentName: String) {
    mdx(id: { eq: $id }) {
      id
      body
      mdxAST
      frontmatter {
        componentName
        navigationLabel
      }
    }
    componentMetadata(displayName: { eq: $componentName }) {
      id
      displayName
      props {
        id
        name
        required
        type {
          name
          value
        }
        defaultValue {
          value
        }
      }
    }
  }
`;

export default PageTemplate;
