import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { pxToRem } from '../../utils/styles';
import { scrollIntoView, replaceState } from '../../utils/dom';
import { htmlDecode } from '../../utils/strings';
import { Link } from '../link/link';
import { useTocScrollSpy, useTocJumpTo } from './table-of-contents-hooks';
// eslint-disable-next-line import/no-cycle
import { makeTocMenu } from './utils';

const ARIA_CURRENT_TAG = 'location';

// Clicking on hashed links is triggering page rerenders, possibly because the
// @reach/router Gatsby is using is listening for URL state changes which triggers
// page rerenders and elements to shift/resize causing mismatched deeplink positions.
// Here we use native history to update the browser state and scroll the element into view
export const onClickTocHashLink = (evt) => {
  evt.preventDefault();
  const url = new URL(evt.currentTarget.href);
  const hashId = url.hash.replace('#', '');
  replaceState(`${url.pathname}${url.hash}`);
  scrollIntoView(document.getElementById(hashId));
};

const renderNestedMenu = (menu = [], currentUri, currentHeading, depth = 0) => {
  if (!menu.length) return null;
  const currentHeadingId =
    currentHeading && currentHeading.id ? currentHeading.id : null;
  return (
    <TableOfContentsList depth={depth}>
      {menu.map((menuItem) => {
        const isCurrent =
          currentUri === menuItem.hash || currentHeadingId === menuItem.id;
        return (
          <TableOfContentsListItem depth={depth} key={menuItem.value}>
            <TableOfContentsLink
              aria-current={isCurrent ? ARIA_CURRENT_TAG : false}
              depth={depth}
              to={menuItem.hash}
              onClick={onClickTocHashLink}
            >
              {htmlDecode(menuItem.value)}
            </TableOfContentsLink>
            {renderNestedMenu(
              menuItem.menu,
              currentUri,
              currentHeading,
              depth + 1,
            )}
          </TableOfContentsListItem>
        );
      })}
    </TableOfContentsList>
  );
};

export const TableOfContentsLink = styled(Link)`
  align-self: flex-start;
  display: flex;
  padding: 0.25rem 1em 0.25rem 0;
  position: relative;
  text-decoration: none;
  ${({ depth }) =>
    depth === 0
      ? css`
          font-weight: 500;
        `
      : css`
          font-weight: 400;
        `}
  &:hover {
    color: var(--ds-color-chicago-30);
  }
  &:focus {
    box-shadow: 0 0 0 0.125rem var(--ds-color-hong-kong-55);
    outline: solid transparent;
  }
  &:active {
    color: var(--ds-color-london-5);
  }
  &[aria-current="${ARIA_CURRENT_TAG}"] {
    color: var(--ds-color-london-5);
    font-weight: 500;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMaxWidth.xlarge`
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `}
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    ${({ depth }) =>
      depth === 0
        ? css`
            padding-left: var(--ds-grid-gap);
          `
        : css`
            padding-left: var(--ds-grid-gutter);
          `}
    &::before {
      background-color: currentColor;
      bottom: 0;
      content: '';
      height: ${pxToRem(22)};
      left: 0;
      margin: auto;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: var(--ds-interactions-transition);
      width: ${pxToRem(3)};
    }
    &:hover::before,
    &[aria-current="${ARIA_CURRENT_TAG}"]::before {
      opacity: 1;
    }
  `}
`;

export const TableOfContentsListItem = styled.li`
  display: grid;
  padding: 0;
  ${({ depth }) =>
    depth === 0
      ? css`
          border-bottom: var(--ds-border-rule);
          grid-template-columns: minmax(7em, 1fr) 4fr;
          padding: 0.25rem 0;

          &:last-of-type {
            border-bottom: none;
          }
        `
      : css`
          grid-template-columns: 1fr;
        `}
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    border-bottom: none;
    display: block;
    padding: 0;
  `}
`;

export const TableOfContentsList = styled.ol`
  display: grid;
  ${({ depth }) =>
    depth === 0
      ? css`
          grid-template-columns: 1fr;
        `
      : css`
          /* makes all secondary list columns obey each other by
          being at least 8em's wide, but break to a new line
          when the cell content > 25% */
          grid-template-columns: repeat(auto-fill, minmax(8em, 0.25fr));
        `}
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    display: block;
  `}
`;

export const TableOfContentsTitle = styled.h4`
  border-bottom: var(--ds-border-rule);
  font-size: var(--ds-type-scale--3);
  font-weight: 500;
  line-height: var(--ds-type-leading-upper);
  margin: 0;
  padding: 0 0 0.5rem;
  text-transform: uppercase;
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    padding-top: 0.75rem;
    margin: 0 var(--ds-grid-gap) 0.5rem;
  `}
`;

export const TableOfContentsWrapper = styled.div`
  box-sizing: border-box;
  ${({ theme }) => theme.mediaMinWidth.xlarge`
    background-color: var(--dsw-color-london-98);
    padding: 0 0 1rem 0;
  `}
`;

const UnstyledTableOfContents = ({ className, headings, currentUri }) => {
  const menu = makeTocMenu(headings);
  const [currentHeading] = useTocScrollSpy(headings, currentUri);

  useTocJumpTo(headings, currentUri);

  return (
    <aside className={className}>
      <TableOfContentsWrapper>
        <TableOfContentsTitle>Contents</TableOfContentsTitle>
        {renderNestedMenu(menu, currentUri, currentHeading)}
      </TableOfContentsWrapper>
    </aside>
  );
};

UnstyledTableOfContents.propTypes = {
  className: PropTypes.string,
  headings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUri: PropTypes.string,
};

UnstyledTableOfContents.defaultProps = {
  className: '',
  currentUri: undefined,
};

export const TableOfContents = styled(UnstyledTableOfContents)`
  color: var(--ds-color-london-5);
  font-family: var(--ds-type-system-sans);
  font-size: var(--ds-type-scale-0);
  font-weight: 400;
  line-height: var(--ds-type-leading-lower);
`;

export default TableOfContents;
