import { makeUrlHash, slugify } from '../../utils/strings';
import * as Mdx from '../mdx-components/mdx-components';

export const HEADING_DEPTH_SECONDARY = 2;
export const HEADING_DEPTH_TERTIARY = 3;

export const regexpStripHtml = new RegExp('<\\w+>.*</\\w+>');
export const regexpCaptureTagAndValue = new RegExp(
  '<(\\w+)\\b[^>]*>(.*?)</\\w+>',
);

/**
 * gatsby-plugin-mdx generates a list of markdown nodes which is passed
 * into this function to generate a nested list of headings and returns
 * a nested menu 2 levels deep standard markdown headings as well as
 * custom JSX tags.
 *
 * @param {object} mdxAst parsed mdx
 * @param {object[]} mdxAst.children mdx child nodes/tags
 * @returns {object[]} headings [{ depth: <number>, value: <string> }]
 */
export const getHeadingsFromMdxAst = (mdxAst) =>
  mdxAst.children.reduce((acc, node) => {
    // by default gatsby-plugin-mdx picks up all standard markdown formatting
    if (node.type === 'heading' && node.depth >= HEADING_DEPTH_SECONDARY) {
      const [firstChild] = node.children;
      acc.push({
        id: slugify(firstChild.value),
        depth: node.depth,
        value: firstChild.value,
        slug: slugify(firstChild.value),
        hash: makeUrlHash(firstChild.value),
      });
    }
    if (node.type === 'jsx') {
      // matches any html/jsx tags, returning the tag name and value
      // which we use to specify the depth
      // https://www.regular-expressions.info/examples.html
      const match = node.value.match(regexpCaptureTagAndValue);
      if (match) {
        const tagName = match[1];
        const value = match[2];
        // strip out any nested HTML/JSX elements since we only want
        // the first-level text values
        const valueHtmlStripped = value.replace(regexpStripHtml, '').trim();
        let parentId = '';
        switch (tagName) {
          case Mdx.MdxH2.displayName:
          case Mdx.MdxHeaderSecondary.displayName:
            acc.push({
              id: slugify(valueHtmlStripped),
              depth: HEADING_DEPTH_SECONDARY,
              value: valueHtmlStripped,
              slug: slugify(valueHtmlStripped),
              hash: makeUrlHash(valueHtmlStripped),
            });
            break;

          case Mdx.MdxH3.displayName:
          case Mdx.MdxHeaderTertiary.displayName:
          case Mdx.MdxHeaderTertiaryVariant.displayName:
            // To create unique id and hash values for these headings we find
            // the parent heading id and prefix it to the value.
            for (let i = 0; i < acc.length; i += 1) {
              const reversedAcc = [...acc].reverse();

              if (reversedAcc[i] && reversedAcc[i].depth === 2) {
                parentId = reversedAcc[i].id;
                break;
              }
            }

            acc.push({
              id: slugify(`${parentId} ${valueHtmlStripped}`),
              depth: HEADING_DEPTH_TERTIARY,
              value: valueHtmlStripped,
              slug: slugify(valueHtmlStripped),
              hash: makeUrlHash(`${parentId} ${valueHtmlStripped}`),
            });
            break;

          default: {
            break;
          }
        }
      }
    }
    return acc;
  }, []);

/**
 * Takes a list of Gatsby generated headings from gatsby-plugin-mdx
 * and returns a nested menu 2 levels deep of H1 and H2 titles
 *
 * @param {object[]} headings MDX file headings generated from Gatsby
 * @param {number} headings[].depth heading depth
 * @returns {object[]} nested menu
 */
export const makeTocMenu = (headings = []) =>
  headings.reduce((acc, curr) => {
    // extend the current heading by adding a field for potential submenus
    const item = {
      ...curr,
      menu: [],
    };

    switch (curr.depth) {
      case HEADING_DEPTH_SECONDARY: {
        acc.push(item);
        break;
      }
      case HEADING_DEPTH_TERTIARY: {
        if (acc.length >= 1) {
          // only if we already have at least one H1
          acc[acc.length - 1].menu.push(item);
        }
        break;
      }
      default: {
        // nothing yet
      }
    }
    return acc;
  }, []);
