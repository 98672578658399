import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as propTypes from '../../proptypes';
import {
  stringifyPropTypeValues,
  getDefaultValue,
} from './component-props-utils';

const Cell = styled.div`
  padding: 0.5rem 0;
  &:first-child {
    color: var(--ds-color-chicago-30);
    font-weight: 700;
  }
  /* stylelint-disable-next-line order/order */
  ${({ theme }) => theme.mediaMinWidth.medium`
    padding: 0.75rem 0;
  `}
`;

const Row = styled.div`
  border-bottom: var(--ds-border-rule);
  ${({ theme }) => theme.mediaMinWidth.medium`
    display: grid;
    grid-column-gap: var(--ds-grid-gap);
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
  `}
`;

const Table = styled.div``;

const ComponentProps = styled(({ componentMeta, className }) => {
  if (!componentMeta) return null;
  return (
    <div className={className}>
      <Table>
        {componentMeta.props.map((prop) => (
          <Row key={prop.id}>
            <Cell>{prop.name}</Cell>
            <Cell>{stringifyPropTypeValues(prop.type)}</Cell>
            <Cell>
              {prop.required && <span>required</span>}
              {prop.defaultValue && (
                <span> = {getDefaultValue(prop.defaultValue)}</span>
              )}
            </Cell>
          </Row>
        ))}
      </Table>
    </div>
  );
})`
  border-top: var(--ds-border-rule);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: var(--ds-type-scale--1);
  line-height: var(--ds-type-leading-lower);
`;

ComponentProps.defaultProps = {
  componentMeta: undefined,
  className: '',
};

ComponentProps.propTypes = {
  componentMeta: propTypes.componentMetadata,
  className: PropTypes.string,
};

export default ComponentProps;
