import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { StyleSheetManager } from 'styled-components';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import ReactResizeDetector from 'react-resize-detector';

import { DESIGN_SYSTEM_STYLES } from '../../constants';

const getBackgroundColor = (background) => {
  switch (background) {
    case 'inverse':
      return 'var(--ds-color-london-20)';
    case 'branded':
      return 'var(--ds-color-economist-red)';
    default:
      return 'var(--ds-color-london-100)';
  }
};

const StyledWrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.background)};
  border: ${(props) => {
    switch (props.background) {
      case 'inverse':
        return 'var(--ds-color-london-20)';
      case 'branded':
        return 'var(--ds-color-economist-red)';
      default:
        return 'var(--ds-border-rule)';
    }
  }};
  box-sizing: border-box;
  display: flex;
  padding: 27px;
  width: 100%;
  ${({ theme }) => theme.mediaMinWidth.medium`
    border: none;
  `}
`;

// When the iframe size is calculated it does not take into consideration
// components styles such as border shadow. To prevent these from being
// 'cut off' a padding of 5px is added within the iframe.
const FrameContent = styled.div`
  /* triggers reflow so that the frame content obeys any surrounding margins */
  overflow: hidden;
  padding: 5px;
  /* Workaround allowing the masthead menu to be displayed on small screen sizes */
  .ds-masthead.is-open {
    position: relative;
  }
`;

const ComponentFrame = ({
  background, // eslint-disable-line react/prop-types
  children,
  iframe,
  ...props
}) => {
  const [containerHeight, setHeight] = useState();
  const iframeRef = React.createRef();

  return (
    <StyledWrapper background={background}>
      {iframe ? (
        <Frame
          head={
            <link
              type='text/css'
              rel='stylesheet'
              href={DESIGN_SYSTEM_STYLES}
            />
          }
          ref={iframeRef}
          style={{
            height: containerHeight,
            background: getBackgroundColor(background),
          }}
          {...props}
        >
          <FrameContextConsumer>
            {(frameContext) => (
              // This allows the use of styled components within the iframe
              <StyleSheetManager target={frameContext.document.head}>
                <FrameContent>{children}</FrameContent>
              </StyleSheetManager>
            )}
          </FrameContextConsumer>
          <ReactResizeDetector
            handleHeight
            onResize={(width, height) => {
              setHeight(height);
            }}
          />
        </Frame>
      ) : (
        children
      )}
    </StyledWrapper>
  );
};

ComponentFrame.defaultProps = {
  background: 'default',
  iframe: false,
};

ComponentFrame.propTypes = {
  background: PropTypes.oneOf(['default', 'inverse', 'branded']),
  iframe: PropTypes.bool,
};

export default styled(ComponentFrame)`
  background-color: ${(props) =>
    props.inverse ? 'var(--ds-color-london-20)' : 'var(--ds-color-london-100)'};
  display: block;
  width: 100%;
`;
