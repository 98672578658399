import { useState, useEffect } from 'react';

import { replaceState, scrollIntoView } from '../../utils/dom';

export const ARTICLE_START_ID = '__article_start__';
export const ARTICLE_END_ID = '__article_end__';

export const DOM_TIMEOUT_JUMP_TO = 600;
export const DOM_TIMEOUT_OBSERVERS = 200;
export const OBSERVER_OPTIONS = {
  threshold: 1,
  // triggers active when within 10px of contact from the top
  rootMargin: '-10px 0px 0px 0px',
};

/**
 * Creates a list of indexes of the
 * headings currently visible on the page
 *
 * @param {string[]} headingIds array of available heading ID's
 * @param {string[]} headingIdsInView array of heading ID in view
 * @returns {number[]} indexes of headings in view
 */
export const mapHeadingIndexesInView = (
  headingIds = [],
  headingIdsInView = [],
) =>
  headingIds
    .map((headingId, i) => (headingIdsInView.includes(headingId) ? i : null))
    .filter(Number.isInteger);

/**
 * Returns the first heading preceding the currently visible headings
 * on the page.
 *
 * @param {object[]} headings list of available headings
 * @param {number[]} indexesInView indexes of headings in view
 * @returns {object} heading object
 */
export const getPrecedingHeading = (headings = [], indexesInView = []) => {
  if (!headings.length) return null;
  const firstIndex = indexesInView[0] || 0;
  const precedingIndex = Math.max(0, firstIndex - 1);
  return headings[precedingIndex];
};

export const useTocJumpTo = (headings = [], currentUri = '') => {
  useEffect(() => {
    let timer;
    const [firstHeading] = headings;
    if (window.location.hash !== firstHeading.hash) {
      const hashId = window.location.hash.replace('#', '');
      timer = setTimeout(() => {
        scrollIntoView(document.getElementById(hashId), {
          behavior: 'smooth',
        });
      }, DOM_TIMEOUT_JUMP_TO);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [currentUri]);
};

export const useTocScrollSpy = (headings = [], currentUri = '') => {
  // private var since we don't want to trigger re-renders when the
  // Intersection Observer detects elements entering/exiting the page.
  let headingIdsInView = [];

  const headingIds = headings.map(({ id }) => id);
  const [currentHeading, setCurrentHeading] = useState(headings[0]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(({ target, isIntersecting }) => {
        // add or remove the heading ID to the stack of headings currently
        const headingIdExists = headingIdsInView.includes(target.id);
        if (isIntersecting && !headingIdExists)
          headingIdsInView.push(target.id);
        if (!isIntersecting && headingIdExists)
          headingIdsInView.splice(headingIdsInView.indexOf(target.id), 1);
      });

      const indexesInView = mapHeadingIndexesInView(
        headingIds,
        headingIdsInView,
      );

      const precedingHeading = getPrecedingHeading(headings, indexesInView);

      const { scrollY } = window;
      const windowHeight = window.innerHeight;
      const scrolledPastWindowHeight = scrollY >= windowHeight;
      const isAtTop = scrollY === 0;
      const isAtBottom = windowHeight + scrollY >= document.body.offsetHeight;

      let changedHeading = null;
      if (isAtTop || headingIdsInView.includes(ARTICLE_START_ID)) {
        [changedHeading] = headings;
      } else if (
        isAtBottom ||
        (headingIdsInView.includes(ARTICLE_END_ID) && scrolledPastWindowHeight)
      ) {
        changedHeading = headings[headings.length - 1];
      } else if (currentHeading.slug !== precedingHeading.slug) {
        changedHeading = precedingHeading;
      }

      if (changedHeading) {
        setCurrentHeading(changedHeading);
        replaceState(`${window.location.pathname}${changedHeading.hash}`);
      }
    }, OBSERVER_OPTIONS);

    // because we can't attach an observer to the MDX components we
    // have to query the DOM once the page has loaded. If the delay/timeout
    // causes issues perhaps we could think about creating a provider to
    // track observed nodes once the MDXRenderer has rendered
    const timer = setTimeout(() => {
      const els = headingIds
        .map((id) => document.getElementById(id))
        .filter(Boolean);
      els.unshift(document.getElementById(ARTICLE_START_ID));
      els.push(document.getElementById(ARTICLE_END_ID));
      Array.from(els).forEach((el) => observer.observe(el));
    }, DOM_TIMEOUT_OBSERVERS);

    // reset everything when the component is re-rendered/mounted
    return () => {
      clearTimeout(timer);
      observer.disconnect();
      headingIdsInView = [];
    };

    // only re-observe elements when the page URI changes
  }, [currentUri]);

  return [currentHeading];
};
