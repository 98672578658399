export const PROP_VALUE_SEPARATOR = ' | ';

export const specialPropCases = {
  ENUM: 'enum',
  ARRAY_OF: 'arrayOf',
  UNION: 'union',
};

export const specialPropCaseValues = Object.values(specialPropCases);

/**
 * Returns a string of propType values
 *
 * @param {object} propType propType object
 * @param {string} propType.name propType name
 * @param {array|object} propType.value propType value
 * @returns {string} the value of prop-types
 */
export const stringifyPropTypeValues = (propType = {}) => {
  const { value, name } = propType;
  if (!specialPropCaseValues.includes(name)) return name;

  switch (name) {
    case specialPropCases.ENUM:
      return `${value.map((item) => item.value).join(PROP_VALUE_SEPARATOR)}`;

    case specialPropCases.ARRAY_OF:
      if (value.name === 'shape') {
        return `[{${Object.keys(value.value).join(PROP_VALUE_SEPARATOR)}}]`;
      }
      if (value.name === 'string') {
        return `[${value.name}]`;
      }
      // eslint-disable-next-line no-console
      console.warn('No case for arrayOf:', value.name);
      return null;

    case specialPropCases.UNION:
      return `${value.map((item) => item.name).join(PROP_VALUE_SEPARATOR)}`;

    default:
      // eslint-disable-next-line no-console
      console.warn('No case for prop-type:', name);
      return null;
  }
};

/**
 * Returns the default value property from a propType, handling
 * empty strings as special cases
 *
 * @param {object} defaultValue the default value object
 * @param {any} defaultValue.value the value
 * @returns {any} number, string, bool, ...
 */
export const getDefaultValue = (defaultValue = {}) =>
  ["''", ''].includes(defaultValue.value)
    ? '[Empty String]'
    : defaultValue.value;
